import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'


const useStyles = makeStyles(theme => ({
  fab: {
    // backgroundColor: '#fff',
    position: 'fixed',
    right: 30,
    bottom: 20,
  }
}))


export default function FabButton(props) {
  const classes = useStyles()

  const { handleClick, icon } = props

  return (
    handleClick ? (
      <Fab color="primary" className={classes.fab} aria-label='add' onClick={handleClick}>
        {icon}
      </Fab>
    )
      : (
        <Fab color="primary" className={classes.fab} aria-label='add' type='submit'>
          {icon}
        </Fab>
      )
  )
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import FabButton from '../component/layout/FabButton'
import SaveIcon from '@material-ui/icons/Save'
import uuid from 'uuid';
import mainLayoutActions from '../action/mainLayout'
// import appApi from '../service/application'
// import appActions from '../action/application'
import placeholder from '../resource/app-placeholder.png'
import { Prompt, useHistory } from 'react-router'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CancelIcon from '@material-ui/icons/Cancel'
import { useBeforeunload } from 'react-beforeunload'
//import appService from '../service/application'
import appAction from '../action/application'
import { routes } from '../helper/routes'
import { KeyboardDatePicker } from '@material-ui/pickers'
// import { set } from 'lodash-es'
// import { KeyboardDatePicker } from 'formik-material-ui-pickers'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  },
  grid: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(4)
  }
}))

const genAppId = () => 'app_' + uuid.v4().substring(25, 35)


const isValidExtra = value => {
  let isValid = true
  if (value) {
    try {
      JSON.parse(value)
    }
    catch (err) {
      isValid = false
    }
  }
  return isValid
}

const validationSchema = yup.object({
  title: yup
    .string('Immetti titolo')
    .min(3, 'Minimo 3 caratteri')
    .max(20, 'Massimo 10 caratteri')
    .required('Titolo è richiesto'),
  description: yup
    .string('Immetti descrizione')
    .max(255, 'Massimo 255 caratteri'),
  asset_base_url: yup
    .string('Immetti Url base asset')
    .url()
    .max(255, 'Massimo 255 caratteri'),
  textures_subpath: yup
    .string('Immetti Sottocartella textures')
    .max(255, 'Massimo 255 caratteri'),
  models_subpath: yup
    .string('Immetti Sottocartella modelli')
    .max(255, 'Massimo 255 caratteri'),
  customer: yup
    .string('Immetti Cliente')
    .max(255, 'Massimo 255 caratteri'),
  expires_on: yup
    .date('Immetti Data scadenza')
    .nullable(),
  extra: yup
    .string('Immetti Extra')
    .test("is-json-object", "Richiesto oggetto json", (value, context) => isValidExtra(value)),
  disabled: yup
    .boolean()
})


function AppForm(props) {
  const classes = useStyles()
  const history = useHistory()
  const { dispatch, edit, app } = props
  const [image, setImage] = React.useState(placeholder)
  const [file, setFile] = React.useState()
  const [isImageEmpty, setImageEmpty] = React.useState(true)
  const [dragCount, setDragCount] = React.useState(0)
  const [langs, setLangs] = React.useState({
    it: true,
    en: false,
    es: false,
    fr: false,
    de: false,
    ru: false,
    pt: false,
    nl: false,
    zh: false
  });
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(true)

  // console.log('AppCreatePage render')

  const handleChangeLang = event => setLangs({ ...langs, [event.target.name]: event.target.checked })

  useBeforeunload(event => {
    if (shouldBlockNavigation)
      event.preventDefault()
  })

  const formik = useFormik({
    initialValues: {
      app_id: edit ? app.app_id : genAppId(),
      // route_id: edit ? app.route_id : '',
      title: edit ? (app.title || '') : '',
      description: edit ? (app.description || '') : '',
      asset_base_url: edit ? (app.asset_dir_base_url || '') : '',
      textures_subpath: edit ? (app.textures_subpath || '') : '',
      models_subpath: edit ? (app.models_subpath || '') : '',
      customer: edit ? (app.customer_name || '') : '',
      expires_on: edit ? app.expires_on : null,
      disabled: edit ? app.is_disabled : false,
      extra: edit ? (app.extra || '') : '',
    },
    validationSchema,
    onSubmit: async values => {
      // console.log('onSubmit is edit?', edit)
      // console.log('onSubmit values', values)
      const app = {
        appId: values.app_id,
        routeId: Array.from(values.title).map(c => c.match(/[a-zA-Z0-9]/i) ? c : '').join(''),
        title: values.title,
        disabled: values.disabled,
        description: values.description,
        // thumb: file,
        assetDirBaseUrl: values.asset_base_url,
        texturesSubpath: values.textures_subpath,
        modelsSubpath: values.models_subpath,
        customerName: values.customer,
        expiresOn: values.expires_on,
        extra: values.extra
      }
      // console.log('onSubmit app', app)
      try {
        if (!edit) {
          // se l'immagine è impostata
          if (file && !isImageEmpty) {
            // console.log('create app file', file)
          }
          await dispatch(appAction.createOne(app))
          setShouldBlockNavigation(false)
          await dispatch(appAction.readList())
          history.push(routes.home)
        }
        else {
          // aggiorno immagine se l'immagine è cambiata
          if (file !== app.img_url && !isImageEmpty) {
            // console.log('edit app file', file)
          }
          await dispatch(appAction.updateOne(app))
          setShouldBlockNavigation(false)
        }
      }
      catch (err) { }
    }
  })

  const getTextField = (label, prop, multiline) => (
    <TextField
      size="small"
      variant="outlined"
      id={prop}
      name={prop}
      label={label}
      value={formik.values[prop]}
      onChange={formik.handleChange}
      error={formik.touched[prop] && Boolean(formik.errors[prop])}
      helperText={formik.touched[prop] && formik.errors[prop]}
      multiline={multiline}
    />
  )

  // console.log('formik', formik)
  const { dirty } = formik
  useEffect(() => {
    // console.log('formik dirty', dirty)
    setShouldBlockNavigation(dirty)
  }, [dirty])

  useEffect(() => {
    // console.log('useEffect edit', app)
    if (edit) {
      //
    }
  }, [app, edit])

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    const validImageTypes = ['image/jpeg', 'image/png']
    const files = e.dataTransfer.files
    if (files && files.length > 0 && validImageTypes.includes(files[0].type)) {
      const file = e.dataTransfer.files[0]
      const objectUrl = URL.createObjectURL(file)
      setImage(objectUrl)
      setFile(file)
      setImageEmpty(false)
    }
    else {
      dispatch(mainLayoutActions.errorSnackbar('Immagine non valida'))
    }
    setDragCount(0)
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragCount(dragCount + 1)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragCount(dragCount - 1)
  }

  const handleImageReset = () => {
    setImage(placeholder)
    setImageEmpty(true)
  }

  return (
    <div>
      <Prompt
        when={shouldBlockNavigation}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Box className={classes.content}>
        <Box>
          <Typography variant='h6'>{edit ? 'Modifica' : 'Crea nuova '} applicazione</Typography>
        </Box>
        <Box mt={4}>
          <form className={classes.root} noValidate /*autoComplete="off"*/ onSubmit={formik.handleSubmit}>
            <Grid container className={classes.grid} spacing={8}>

              <Grid item xs={6} style={{ minWidth: 500 }}>
                <Box display='flex' mb={2}>
                  <Box>
                    <Card
                      style={{ width: 160, height: 160, border: dragCount > 0 ? '3px solid #0089ff' : 'transparent', position: 'relative' }}
                      onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}
                    >
                      {!isImageEmpty &&
                        <div style={{ position: 'absolute', top: 0, right: 0, padding: 2 }}>
                          <IconButton aria-label="delete" className={classes.margin} size="small" onClick={handleImageReset} color='primary'>
                            <CancelIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      }
                      <img src={image} alt='app' style={{ width: '100%', height: '100%' }} />
                    </Card>
                  </Box>
                  <Box ml={4}>
                    <TextField size="small" id="app-id" label="Id" variant="outlined"
                      value={formik.values.app_id}
                      disabled />
                    {getTextField('Titolo', 'title', false)}
                    {getTextField('Descrizione', 'description', true)}
                  </Box>
                </Box>
                <Box>
                  {getTextField('Url base asset', 'asset_base_url', false)}
                  {getTextField('Sottocartella textures', 'textures_subpath', false)}
                  {getTextField('Sottocartella modelli', 'models_subpath', false)}
                </Box>
              </Grid>

              <Grid item xs={6} style={{ minWidth: 500 }}>
                {getTextField('Cliente', 'customer', false)}

                <KeyboardDatePicker
                  id='expires_on'
                  name='expires_on'
                  label='Data scadenza' size="small" inputVariant="outlined" variant="inline" format="DD/MM/yyyy"
                  value={formik.values.expires_on}
                  onChange={val => formik.setFieldValue('expires_on', val)}
                />

                {getTextField('Extra', 'extra', true)}

                <Box mt={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" size='small'>
                        <FormLabel component="legend">Disabilita applicazione</FormLabel>
                        <FormGroup style={{ marginTop: 10 }}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values.disabled} onChange={formik.handleChange} id="disabled" name="disabled" size='small' />
                            }
                            label="Disabilitata"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" size='small'>
                        <FormLabel component="legend">Lingue disponibili</FormLabel>
                        <Grid container>
                          <Grid item xs={6}>
                            <FormGroup style={{ marginTop: 10 }}>
                              <FormControlLabel control={<Checkbox checked={langs.it} onChange={handleChangeLang} name="it" size='small' />} label="Italiano" />
                              <FormControlLabel control={<Checkbox checked={langs.en} onChange={handleChangeLang} name="en" size='small' />} label="Inglese" />
                              <FormControlLabel control={<Checkbox checked={langs.es} onChange={handleChangeLang} name="es" size='small' />} label="Spagnolo" />
                              <FormControlLabel control={<Checkbox checked={langs.fr} onChange={handleChangeLang} name="fr" size='small' />} label="Francese" />
                              <FormControlLabel control={<Checkbox checked={langs.de} onChange={handleChangeLang} name="de" size='small' />} label="Tedesco" />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={6}>
                            <FormGroup style={{ marginTop: 10 }}>
                              <FormControlLabel control={<Checkbox checked={langs.ru} onChange={handleChangeLang} name="ru" size='small' />} label="Russo" />
                              <FormControlLabel control={<Checkbox checked={langs.pt} onChange={handleChangeLang} name="pt" size='small' />} label="Portoghese" />
                              <FormControlLabel control={<Checkbox checked={langs.nl} onChange={handleChangeLang} name="nl" size='small' />} label="Olandese" />
                              <FormControlLabel control={<Checkbox checked={langs.zh} onChange={handleChangeLang} name="zh" size='small' />} label="Cinese" />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <FabButton /*handleClick={handleSave}*/ icon={<SaveIcon />} />
              </Grid>
            </Grid>
          </form>
        </Box>

      </Box>
    </div>
  )
}



export default connect()(AppForm)

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { /*useHistory,*/ useParams } from 'react-router-dom'
import PageLayout from './PageLayout'
import { connect } from 'react-redux'
// import { routes, replaceAppId } from '../../helper/routes'
import _ from 'lodash'
import { readLocalStorageData } from '../helper/localStorage'
import qs from 'qs'


const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    height: '100%'
  }
}))


function AppPluginPage(props) {
  const classes = useStyles()
  // const history = useHistory()
  const params = useParams()
  const { appMap } = props
  const app = appMap[params.appId] || {}
  const plugin = _.keyBy(app.plugins, 'route_id')[params.plugId] || {}
  const [refresh] = React.useState((new Date()).getTime())

  // console.log('AppPluginPage render', plugin)

  const qsParams = {
    accessToken: readLocalStorageData().access_token,
    api: process.env.REACT_APP_BACKEND_BASE_URL,
    appId: params.appId,
    pluginId: plugin.id
  }

  // console.log('plugin', plugin)

  const pluginRendered = plugin && plugin.title && plugin.url

  return (
    <PageLayout overflow={false}>
      {pluginRendered &&
        <iframe
          className={classes.content}
          frameBorder="0"
          title={plugin.title}
          src={plugin.url + '?' + qs.stringify(qsParams) + `&refresh=${refresh}`}
          allow="clipboard-write"
        />
      }
    </ PageLayout>
  )
}

const mapStateToProps = store => {
  const { application } = store
  return {
    appMap: application.appMap
  }
}

export default connect(mapStateToProps)(AppPluginPage)

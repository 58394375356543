import axiosCommon from '../helper/axiosCommon'


async function checkToken() {
  const req = axiosCommon.getAxiosParamsAuth('/auth/check-token', 'post', {})
  return await axiosCommon.axiosRequest(req)
}

async function resetPassword(data) {
  const req = axiosCommon.getAxiosParams('/auth/password-reset', 'post', data)
  return await axiosCommon.axiosRequest(req)
}

async function sendResetPasswordLink(data) {
  const req = axiosCommon.getAxiosParams('/auth/send-password-reset', 'post', data)
  return await axiosCommon.axiosRequest(req)
}

async function logout(data) {
  const req = axiosCommon.getAxiosParamsAuth('/auth/logout', 'post', data)
  return await axiosCommon.axiosRequest(req)
}

async function login(data) {
  const req = axiosCommon.getAxiosParams('/auth/login', 'post', data)
  return await axiosCommon.axiosRequest(req)
}


export default {
  checkToken,
  resetPassword,
  sendResetPasswordLink,
  logout,
  login
}

import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import AppsPage from "./AppsPage"
import UsersPage from "./UsersPage"
import RolesPage from "./roles/RolesPage"
import PermissionsPage from "./permissions/PermissionsPage"
import AppHomePage from "./AppHomePage"
import AppCreatePage from "./AppCreatePage"
import AppEditPage from "./AppEditPage"
// import ApplicationsTable from "./applications/ApplicationsTable"
import AppProductsPage from "./products/AppProductsPage"
import AppClasspartsPage from "./classparts/AppClasspartsPage"
import AppInstancepartsPage from "./instanceparts/AppInstancepartsPage"
import AppPatternmixesPage from "./patternmixes/AppPatternmixesPage"
import AppPatternsPage from "./patterns/AppPatternsPage"
import AppColorsPage from "./colors/AppColorsPage"
import AppMaterialsPage from "./materials/AppMaterialsPage"
import AppGeometriesPage from "./geometries/AppGeometriesPage"
import AppMapsPage from "./maps/AppMapsPage"
import AppCompositionsPage from "./compositions/AppCompositionsPage"
import AppConfiguredProductsPage from "./configuredProducts/AppConfiguredProductsPage"
import AppConfiguredPartsPage from "./configuredParts/AppConfiguredPartsPage"
import AppStatisticsPage from "./statistics/AppStatisticsPage"
import AppUsersPage from "./users/AppUsersPage"
// import UsersTable from "./users/UsersTable"
// import RolesTable from "./roles/RolesTable"
// import PermissionsTable from "./permissions/PermissionsTable"
// import MainLayout from '../component/layout/MainLayout'
import layoutActions from '../action/mainLayout'
import userActions from '../action/user'
import roleActions from '../action/role'
import authActions from '../action/auth'
import permissionActions from '../action/permission'
import appActions from '../action/application'
import { routes } from '../helper/routes'
import AppPluginPage from "./AppPluginPage"
// import TestPage from "./TestPage"
// import PageLayout from "./PageLayout"
import authApi from '../service/auth'
// import { readLocalStorageData } from '../helper/localStorage'


function MainPage(props) {
  const { dispatch } = props
  const history = useHistory()

  useEffect(() => {
    // console.log('>>>>>>>> MainPage useEffect')
    const start = async () => {
      let validToken = false
      try {
        const resp = await authApi.checkToken()
        // console.log('resp.valid', resp.valid)
        validToken = resp.valid
        if (!validToken) {
          await dispatch(authActions.logout())
          history.push(routes.login)
          dispatch(layoutActions.errorSnackbar('Token invalido'))
        }
      }
      catch (err) {
        dispatch(layoutActions.errorSnackbar('Server non raggiungibile'))
      }
      if (validToken) {
        Promise.all([
          dispatch(userActions.readProfile()),
          dispatch(appActions.readList()),
          dispatch(roleActions.readList()),
          dispatch(permissionActions.readList()),
        ])
        .catch(err =>  dispatch(layoutActions.errorSnackbar('Errore di inizializzazione: ' + err.message)))
      }
    }
    start()
  }, [dispatch, history])

  // console.log('MainPage render')

  return (
    // <MainLayout {...props}>
    <Switch>
      <Route exact path={routes.home} component={AppsPage} />
      <Route exact path={routes.users} component={UsersPage} />
      <Route path={routes.roles} component={RolesPage} />
      <Route path={routes.permissions} component={PermissionsPage} />
      <Route path={routes.appCreate} component={AppCreatePage} />
      {/* <Route path={routes.test} component={TestPage} /> */}
      <Route exact path={routes.appHome} component={AppHomePage} />
      <Route path={routes.appEdit} component={AppEditPage} />
      <Route path={routes.appStatistics} component={AppStatisticsPage} />
      <Route path={routes.appCompositions} component={AppCompositionsPage} />
      <Route path={routes.appConfiguredProducts} component={AppConfiguredProductsPage} />
      <Route path={routes.appConfiguredParts} component={AppConfiguredPartsPage} />
      <Route path={routes.appProducts} component={AppProductsPage} />
      <Route path={routes.appClassparts} component={AppClasspartsPage} />
      <Route path={routes.appInstanceparts} component={AppInstancepartsPage} />
      <Route path={routes.appPatternmixes} component={AppPatternmixesPage} />
      <Route path={routes.appPatterns} component={AppPatternsPage} />
      <Route path={routes.appColors} component={AppColorsPage} />
      <Route path={routes.appMaterials} component={AppMaterialsPage} />
      <Route path={routes.appGeometries} component={AppGeometriesPage} />
      <Route path={routes.appMaps} component={AppMapsPage} />
      <Route path={routes.appUsers} component={AppUsersPage} />
      <Route path={routes.appPlugin} component={AppPluginPage} />
    </Switch>
    // </MainLayout>
  )
}

export default connect()(MainPage)

import jwt from 'jsonwebtoken'
import { readLocalStorageData } from './localStorage'
import _ from 'lodash'


function isTokenExistentAndValid() {
  const userData = readLocalStorageData()
  return !_.isNil(userData) &&
    userData.hasOwnProperty('access_token') &&
    isValidToken(userData.access_token)
}

function isValidToken(token) {
  let isValidToken = true
  try {
    verifyToken(token)
  }
  catch (error) {
    isValidToken = false
  }
  return isValidToken
}

function verifyToken(token) {
  const publicKey = process.env.REACT_APP_BACKEND_PUBLIC_KEY
  return jwt.verify(token, publicKey,
    {
      algorithms: ['RS256'],
      ignoreNotBefore: true,
      // clockTolerance: 30,
      audience: '2'
    }
  )
}

function decodeToken(token) {
  return jwt.decode(token)
}

export default {
  isTokenExistentAndValid,
  isValidToken,
  verifyToken,
  decodeToken
}

import React from 'react'
// import { connect } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
// import { Box } from '@material-ui/core'
// import PageLayout from './PageLayout'
// import AppCreatePage from './AppCreatePage'
import { /* useHistory, */ useParams } from 'react-router'
import appApi from '../service/application'
import PageLayout from './PageLayout'
import AppForm from './AppForm'
import CenteredSpinner from '../component/CenteredSpinner'

// const useStyles = makeStyles(theme => ({
//   content: {
//     padding: theme.spacing(4)
//   },
// }))


function AppEditPage(props) {
  // const classes = useStyles()
  // const history = useHistory()
  const params = useParams()
  const [app, setApp] = React.useState(null)

  // history.location.pathname

  // console.log('AppEditPage render')
  // console.log('history', history)
  // console.log('params', params)

  const appId = params.appId
  // console.log('appId', appId)
  // console.log('app', app)
  // const app = { title: 'titolo' }

  React.useEffect(() => {
    const readApp = async () => {
      const app = await appApi.readOne(appId)
      // console.log('fetched app', app)
      setApp(app)
    }
    readApp()
  }, [appId])


  const isNullApp = app == null

  return (
    <PageLayout mainDrawer={true} >
      {isNullApp ?
        <CenteredSpinner /> :
        <AppForm edit={true} app={app} />
      }
    </PageLayout>
  )
}

// const mapStateToProps = store => {
//   const { application, mainLayout } = store
//   return {
//     apps: application.list,
//     permissionMap: mainLayout.readProfile.permissionMap
//   }
// }

export default AppEditPage // connect(mapStateToProps)(AppEditPage)

import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment'
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
// import CheckBoxIcon from '@material-ui/icons/CheckBox'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Link from '@material-ui/core/Link'
// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import mioLogo from '../../resource/mio-logo.png'
import mioBackground from '../../resource/mio-background.jpg'
import authActions from '../../action/auth'
import validator from 'validator'
import LinkExternal from '../../component/LinkExternal'
import ReactRouterLink from '../../component/ReactRouterLink'
import { routes } from '../../helper/routes'

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      height: "100vh",
      minHeight: "100%"
    },
    body: {
      background: `url(${mioBackground}) no-repeat center center fixed`,
      backgroundSize: "cover",
      backgroundColor: "#333"
    },
  },
  paper: {
    marginTop: '14vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  card: {
  },
  logo: {
    marginBottom: theme.spacing(2),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "32%"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    border: '1px solid #ced4da',
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  placeholder: {
    marginTop: theme.spacing(1),
    height: 10
  },
  errorMessage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  links: {
    marginTop: theme.spacing(1)
  }
}))



function Login({ dispatch }) {
  const classes = useStyles()
  const history = useHistory()

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isUsernameError, setIsUsernameError] = React.useState(false)
  const [isPasswordError, setIsPasswordError] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  // const [keepConnected, setKeepConnected] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)


  const handleSubmit = async event => {
    event.preventDefault()
    const isInvalidUsername = !validator.isEmail(username)
    const isInvalidPassword = password.trim().length < 4 || /\s/.test(password)
    if (isInvalidUsername || isInvalidPassword) {
      setIsUsernameError(isInvalidUsername)
      setIsPasswordError(isInvalidPassword)
    }
    else {
      setIsSubmitted(true)
      setIsUsernameError(false)
      setIsPasswordError(false)
      try {
        const request = { username, password, keepConnected: false }
        await dispatch(authActions.login(request))
        history.push(routes.home)
      }
      catch (error) {
        setIsSubmitted(false)
      }
    }
  }

  // console.log('Login render')

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Card className={classes.card}>
          <CardContent>
            <Box m={1}>
              <img src={mioLogo} alt="main-logo" className={classes.logo} />
            </Box>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                disabled={isSubmitted}
                error={isUsernameError}
                helperText={isUsernameError ? "Indirizzo email non valido" : null}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Indirizzo email"
                name="email"
                autoComplete="email"
                autoFocus
                spellCheck='false'
                value={username}
                onChange={event => setUsername(event.target.value)}
              />
              <TextField
                disabled={isSubmitted}
                error={isPasswordError}
                helperText={isPasswordError ? "Password non valida" : null}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                spellCheck='false'
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        disabled={isSubmitted}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={event => event.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
              <Box style={{ height: 16 }} />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSubmitted}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    value="remember"
                    onChange={event => setKeepConnected(event.target.checked)}
                    checked={keepConnected}
                    color="primary" />
                }
                label={<Typography variant="body2">Ricordami</Typography>}
              /> */}
              <div className={classes.placeholder}>
                <Fade
                  in={isSubmitted}
                  style={{
                    transitionDelay: isSubmitted ? '800ms' : '0ms',
                  }}
                  unmountOnExit
                >
                  <LinearProgress />
                </Fade>
              </div>
              <Button
                disabled={isSubmitted}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Accedi
              </Button>
              <Grid container className={classes.links}>
                <Grid item xs>
                  <Link to={routes.lostPassword} component={ReactRouterLink} variant="body2">
                    Hai dimenticato la password?
                  </Link>
                </Grid>
                <Grid item>
                  <LinkExternal href="https://mio.studio/" label="Vai al Sito Web" color='primary' />
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}


export default connect()(Login)

import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import PageLayout from './PageLayout'
// import { useHistory } from 'react-router'
import AppForm from './AppForm'

// const useStyles = makeStyles(theme => ({
//   //
// }))




function AppCreatePage(props) {
  // const classes = useStyles()
  // const history = useHistory()

  // console.log('AppCreatePage render')

  return (
    <PageLayout mainDrawer={true} >
      <AppForm
        edit={false}
      />
    </PageLayout>

  )
}

// const mapStateToProps = store => {
//   const { application, mainLayout } = store
//   return {
//     apps: application.list,
//     permissionMap: mainLayout.readProfile.permissionMap
//   }
// }

export default AppCreatePage // connect(/*mapStateToProps*/)(AppCreatePage)

import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import PageLayout from './PageLayout'
import AppCard from '../component/AppCard'
import { routes } from '../helper/routes'
import { useHistory } from 'react-router-dom'
import FabButton from '../component/layout/FabButton'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4)
  },
}))


function AppsPage(props) {
  const classes = useStyles()
  const history = useHistory()
  const { apps, permissionMap } = props

  const handleAppCreate = () => {
    history.push(routes.appCreate)
  }

  // console.log('AppsPage render')

  return (
    <PageLayout mainDrawer={true} >
      <Box className={classes.content}>
        <Grid container spacing={2}>
          {apps.map(app => (
            <Grid key={app.id} item xs={6} sm={3}>
              <AppCard app={app} permissionMap={permissionMap} />
            </Grid>
          ))}
        </Grid>
        {(permissionMap['create-all'] || permissionMap['create-application']) &&
          <FabButton handleClick={handleAppCreate} icon={<AddOutlinedIcon />} />
        }
      </Box>
    </PageLayout>
  )
}

const mapStateToProps = store => {
  const { application, mainLayout } = store
  return {
    apps: application.list,
    permissionMap: mainLayout.readProfile.permissionMap
  }
}

export default connect(mapStateToProps)(AppsPage)

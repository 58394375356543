import { matchPath } from "react-router"

export const routesBasePath = '/'

export const routes = {
  login: `${routesBasePath}login`,
  lostPassword: `${routesBasePath}recupera-password`,
  resetPassword: `${routesBasePath}reimposta-password`,
  home: `${routesBasePath}`,
  users: `${routesBasePath}utenti`,
  roles: `${routesBasePath}utenti/ruoli`,
  permissions: `${routesBasePath}utenti/permessi`,
  appCreate: `${routesBasePath}crea`,
  appHome: `${routesBasePath}:appId`,
  appEdit: `${routesBasePath}:appId/modifica`,
  appStatistics: `${routesBasePath}:appId/statistiche`,
  appPlugin: `${routesBasePath}:appId/:plugId`,
  appProducts: `${routesBasePath}:appId/prodotti`,
  appClassparts: `${routesBasePath}:appId/classi-parte`,
  appInstanceparts: `${routesBasePath}:appId/istanze-parte`,
  appPatternmixes: `${routesBasePath}:appId/patternmix`,
  appPatterns: `${routesBasePath}:appId/pattern`,
  appColors: `${routesBasePath}:appId/colori`,
  appMaterials: `${routesBasePath}:appId/materiali`,
  appGeometries: `${routesBasePath}:appId/geometrie`,
  appMaps: `${routesBasePath}:appId/mappe`,
  appCompositions: `${routesBasePath}:appId/composizioni`,
  appConfiguredProducts: `${routesBasePath}:appId/prodotti-configurati`,
  appConfiguredParts: `${routesBasePath}:appId/parti-configurate`,
  appUsers: `${routesBasePath}:appId/utenti`,
  // test: `${routesBasePath}test`
}

export const isKnownPath = (knownPaths, path) => {
  for (const knownPath of knownPaths) {
    const matchedPath = matchPath(path, { path: knownPath, exact: true, strict: false })
    if (matchedPath) return true
  }
  return false
}

export const replaceAppId = (path, appId) => path.replace(':appId', appId)
export const replaceAppIdPlugId = (path, appId, plugId) => path.replace(':appId', appId).replace(':plugId', plugId)
// export const replaceEditPath = (path, replacement) => path.replace(':id', replacement)
